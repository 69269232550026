
.number-card {
    position: relative;
    overflow: visible;
    .number {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        overflow: visible;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 24px);
            height: calc(100% + 24px);
            border: 12px solid var(--v-grey-lighten5);
            border-radius: 50%;
            z-index: -1;
        }
    }
}
