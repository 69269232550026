
.doctor-bg {
    position: relative;
    height: 300px;
    &::after {
        content: "";
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        bottom: calc(-1 * var(--page-section-padding-y-sm) + var(--grid-gutter-xs));
        width: 200px;
        aspect-ratio: 486 / 794;
        background-image: url(/images/sub/introduction/introduction-doctor.png);
        background-position-y: bottom;
        background-size: contain;
    }
}
@media (min-width: 768px) {
    .doctor-bg {
        height: 100px;
        &::after {
            right: 0;
            transform: initial;
        }
    }
}
@media (min-width: 1024px) {
    .doctor-bg {
        height: auto;
        &::after {
            width: 100%;
            right: 50%;
            transform: translateX(50%);
        }
    }
}
.guideline-image {
    &-01 {
        width: 244px;
    }
    &-02 {
        width: 182px;
    }
    @media (min-width: 768px) {
        &-01,
        &-02 {
            width: auto;
        }
    }
}
.marks-wrap {
    border-top: 1px solid var(--v-primary-darken4);
    border-bottom: 1px solid var(--v-primary-darken4);
}

.introduction-list {
    > li {
        padding-top: 24px;
    }
}
@media (min-width: 768px) {
    .introduction-list {
        > li {
            padding-top: 60px;
        }
    }
}
