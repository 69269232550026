
.principle-tree {
    position: relative;
    &__btn-group {
        padding: 20px;
        border: 1px solid var(--v-primary-base);
        border-radius: 999px;
    }
    &__btn {
        position: relative;
        .v-card__title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -25%);
        }
    }
}
@media (min-width: 768px) {
    .principle-tree {
        &__btn-group {
            padding: 40px;
        }
    }
}
@media (min-width: 1200px) {
    .principle-tree {
        aspect-ratio: 1200 / 616;
        &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        &__btn-group {
            padding: 0;
            border: none;
            width: calc(392 / 1200 * 100%);
            position: absolute;
            bottom: calc(90 / 616 * 100%);
            left: calc(89 / 1200 * 100%);
            z-index: 5;
        }

        &__btn {
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                opacity: 0;
                transition: all ease-out 0.2s;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
