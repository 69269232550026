
.section-bg {
    background-image: url(/images/sub/introduction/team/section-bg.png);
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: center;
}
@media (min-width: 1024px) {
    .section-bg {
        background-size: 100%;
    }
}
.doctor-bg {
    position: relative;
    height: 370px;
    &::after {
        content: "";
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        bottom: calc(-1 * var(--page-section-padding-y-sm) + var(--grid-gutter-xs));
        width: 300px;
        aspect-ratio: 486 / 660;
        background-image: url(/images/sub/introduction/team/team-doctor.png);
        background-position-y: bottom;
        background-size: contain;
    }
}
@media (min-width: 768px) {
    .doctor-bg {
        &::after {
            right: var(--grid-gutter-xs);
            transform: initial;
        }
    }
}
@media (min-width: 1024px) {
    .doctor-bg {
        height: auto;
        &::after {
            width: calc(100% - var(--grid-gutter-xs) * 2);
            right: 50%;
            transform: translateX(50%);
        }
    }
}

@media (min-width: 1200px) {
    .heart-bg {
        position: relative;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
        }
        &::before {
            background-color: #000;
        }
        &::after {
            background-image: url(/images/sub/introduction/team/heart.jpg);
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }
}

.label-img {
    .txt {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}
