
.sigvaris {
    background-color: #fcf2e9;
}
.shorts-card {
    .v-image {
        position: relative;
        &__overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;

            .play-button {
                width: 60px;
                height: 60px;
                border: 6px solid white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.mobile-full-width {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100%;
        background-color: inherit;
        z-index: -1;
    }
}
@media (min-width: 768px) {
    .mobile-full-width::before {
        display: none;
    }
}
