
.diagnosis-icon {
    max-width: 26px;
    margin-right: 6px;
}
@media (min-width: 1024px) {
    .diagnosis-icon {
        max-width: 36px;
        margin-right: 10px;
    }
}
